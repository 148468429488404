<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div id="container " class="md-layout md-gutter md-alignment-center">
          <div class="md-layout-item">
            <h4 class="title" style="font-weight:350;">
              Novo dependente :
            </h4>
          </div>
          <div>
            <md-button
              type="button"
              class="md-raised mt-4 md-success"
              @click="addNovoDependente()"
            >
              Adicionar
            </md-button>
          </div>
        </div>
        <div v-for="(dependente, index) in dependentes" :key="index">
          <div
            v-if="!isFetching"
            style="padding-top:15px;display:inline-block;"
          >
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Dependente - {{ index }}
              </h5></span
            >
          </div>
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="padding-left:15px;display:inline-block;"
          >
            <ValidationProvider name="pesquisa">
              <md-autocomplete
                class="uppercase"
                v-model="pesquisas[index].pesquisa.texto"
                :md-options="dependentesOptionAuto"
                :md-fuzzy-search="false"
                @md-selected="selectedDependente(index)"
              >
                <label>Pesquisar CPF ou Nome</label>
              </md-autocomplete>
            </ValidationProvider>
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                :name="'Nome Completo-' + index"
                rules="required|min:3|max:100"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    class="uppercase"
                    v-model="dependente.nomeCompleto"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                :name="'CPF-' + index"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="dependente.cpfDependente"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                :name="'Data Nascimento-' + index"
                :rules="{
                  regex: /^([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})$/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Nascimento</label>
                  <md-input
                    v-model="dependente.dataNascimento"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-field>
                <label>Idade </label>
                <md-input
                  type="numeric"
                  :maxlength="3"
                  disabled
                  :value="idadeSetValue(index)"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'Sexo-' + index"
                rules="required"
                v-slot="{ errors }"
              >
                <label>Sexo </label>
                <md-radio v-model="dependente.sexoDependente" value="M"
                  >Masculino</md-radio
                >
                <md-radio v-model="dependente.sexoDependente" value="F"
                  >Feminino</md-radio
                >
                <br />
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                :name="'Parentesco-' + index"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="parentescoSelect">Parentesco</label>
                  <md-select
                    v-model="dependente.parentesco"
                    name="parentescoSelect"
                  >
                    <md-option
                      v-for="item in parentescoSelectOption"
                      :key="item.id"
                      :label="item.parentesco"
                      :value="item.parentesco"
                    >
                      {{ item.parentesco }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-xs-2">
            <label>&nbsp;</label>
            <md-button
              type="button"
              class="md-just-icon md-danger"
              @click="removeDependente(index)"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
        <div class="col-xs-2">
          <md-button type="submit" class="md-raised mt-4 md-success">
            CRIAR
          </md-button>
          &nbsp;
          <md-button
            @click="validarProximo()"
            class="md-raised md-success mt-4"
          >
            PROXIMO >
          </md-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { PARENTESCO } from "@/pages/Dashboard/js/parentesco";
import { checkAllResponses } from "@/modules/servidor_publico/js/checkAllResponses";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}

localize("pt_BR", pt_br);

let typingTimer; //timer identifier
let doneTypingInterval = 3000; //time in ms (3 seconds)

export default {
  props: {
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    ...mapGetters("dependente", {
      dependenteState: "dependenteState"
    }),
    user_profile_state: state => state.user_profile,
    cpf() {
      return this.dependentes.map(dependente => dependente.cpfDependente);
    },
    dataNascimento() {
      return this.dependentes.map(dependente => dependente.dataNascimento);
    }
  },
  beforeMount() {
    this.getPapel();
    this.dependentesOptionAuto = [];
  },
  data() {
    return {
      idade: [],
      parentescoSelect: "",
      parentescoSelectOption: PARENTESCO,
      isFetching: true,
      papel: "",
      dependente: {
        idEntidade: "",
        nomeCompleto: "",
        cpfDependente: "",
        sexoDependente: "",
        dataNascimento: "",
        parentesco: ""
      },
      dependentes: [],
      dependenteAuto: [""],
      dependenteOptions: [],
      pesquisa: {
        texto: ""
      },
      pesquisas: [],
      dependentesOptionAuto: []
    };
  },
  mounted() {
    this.loadServidor();
    this.isFetching = false;
  },
  methods: {
    ...mapActions("dependente", [
      "ActionSetDEPENDENTE",
      "ActionGetDependentesByNomeCompletoOrCpf"
    ]),
    ...mapActions("servidor_publico", [
      "ActionSubmitServidorAllData",
      "ActionSetSERVIDOR_PUBLICO"
    ]),
    addNovoDependente: function() {
      this.dependentes.push(Vue.util.extend({}, this.dependente));
      this.pesquisas.push(Vue.util.extend({}, { pesquisa: { texto: "" } }));
    },
    removeDependente: function(index) {
      this.idade.splice(index);
      Vue.delete(this.dependentes, index);
      Vue.delete(this.pesquisas, index);
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    validarProximo() {
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.ActionSetDEPENDENTE(this.dependentes);
            this.emitToParent("enderecos");
            window._Vue.$router
              .push({ name: "Novos Enderecos" })
              .catch(err => {});
          }
        }
      });
    },
    async loadForm() {
      if (this.dependenteState.length > 0) {
        Object.keys(this.dependenteState).map(key => {
          this.pesquisas.push(Vue.util.extend({}, { pesquisa: { texto: "" } }));
          return this.dependentes.push(
            Vue.util.extend({}, this.dependenteState[key])
          );
        });
      } else {
        this.dependentes = JSON.parse(
          JSON.stringify([
            {
              idEntidade: "",
              nomeCompleto: "",
              cpfDependente: "",
              sexoDependente: "",
              dataNascimento: "",
              parentesco: ""
            }
          ])
        );
        this.pesquisas.push(Vue.util.extend({}, { pesquisa: { texto: "" } }));
      }
      this.isFetching = false;
    },
    async loadServidor() {
      //this.loadForm();
      if (
        this.servidorPublicoSelected.cpfServidor &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Novo Cadastro" }).catch(err => {});
      }
    },
    idadeSetValue(index) {
      if (this.idade.length > 0) {
        let idadeFound = this.idade.find(item => {
          if (item.key === index.toString()) {
            return item.idade;
          }
        });
        if (idadeFound) {
          return idadeFound.idade;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    calcularIdade(dobString) {
      let dob = new Date(dobString);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let birthdayThisYear = new Date(
        currentYear,
        dob.getMonth(),
        dob.getDate()
      );
      let age = currentYear - dob.getFullYear();
      if (birthdayThisYear > currentDate) {
        age--;
      }
      return age;
    },
    callIdadeCalc(dataNascimento) {
      if (dataNascimento.toString().length < 10) {
        return 0;
      }
      let data = dataNascimento;
      let partes = data.split("/");
      let join = partes[2] + "-" + partes[1] + "-" + partes[0];
      return this.calcularIdade(join);
    },
    VerificaCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    async submit() {
      try {
        this.ActionSetDEPENDENTE(this.dependentes);
        var papel = this.papel;
        //var form = this.dependentes;

        this.ActionSubmitServidorAllData({
          papel
        }).then(response => {
          checkAllResponses(response);
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    selectedDependente(key) {
      let pesquisa = this.pesquisas[key].pesquisa.texto.split(" - ");
      let index = -1;
      let resultItem = this.dependenteOptions.find(function(item, i) {
        if (item.cpfDependente.toString() === pesquisa[0]) {
          index = i;
          return item;
        }
      });
      this.dependentes[key].nomeCompleto = resultItem.nomeCompleto;
      this.dependentes[key].cpfDependente = resultItem.cpfDependente;
      this.dependentes[key].dataNascimento = resultItem.dataNascimento;
      this.dependentes[key].idDependente = resultItem.idDependente;
      this.dependentes[key].parentesco = resultItem.parentesco;
      this.dependentes[key].sexoDependente = resultItem.sexoDependente;
    },
    doneTyping(val) {
      this.getDependentesByNomeCompletoOrCpf(val).then(response => {
        if (response !== null) {
          let auto = [];
          Object.keys(response).map(key => {
            let dep =
              response[key].cpfDependente.toString() +
              " - " +
              response[key].nomeCompleto;
            auto.push(dep);
          });
          this.dependenteAuto = auto;
          this.dependentesOptionAuto = this.dependenteAuto;
        } else {
          this.dependenteAuto = [""];
          this.dependentesOptionAuto = this.dependenteAuto;
        }
      });
    },
    async getDependentesByNomeCompletoOrCpf(query) {
      try {
        let id = this.servidorPublicoSelected.idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetDependentesByNomeCompletoOrCpf({
          id,
          papel,
          query
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                //title: "Sucesso",
                icon: "warning",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
            }
            return null;
          } else {
            this.dependenteOptions = response.body;
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    cpf(val) {
      Object.keys(this.dependentes).map(key => {
        if (this.dependentes[key].cpfDependente === val[key]) {
          let cpf = cpfMask(val[key].toString());
          this.dependentes[key].cpfDependente = cpf;
        }
      });
    },
    dataNascimento(val) {
      Object.keys(this.dependentes).map(key => {
        if (this.dependentes[key].dataNascimento === val[key]) {
          let data = dataMask(val[key].toString());
          this.dependentes[key].dataNascimento = data;
          if (
            /^([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})$/.test(
              this.dependentes[key].dataNascimento
            )
          ) {
            let idadeCalculada = this.callIdadeCalc(
              this.dependentes[key].dataNascimento
            );
            let indexFound = this.idade.findIndex(item => item.key === key);

            if (indexFound !== -1) {
              this.idade[indexFound] = { key: key, idade: idadeCalculada };
            } else {
              this.idade.push({ key: key, idade: idadeCalculada });
            }
          }
        }
      });
    },
    pesquisas: {
      deep: true,
      handler: function(val) {
        clearTimeout(typingTimer);
        Object.keys(this.pesquisas).map(key => {
          if (this.pesquisas[key].pesquisa.texto === val[key].pesquisa.texto) {
            //this.pensionistas[key].pesquisa.split("-");
            if (!val[key].pesquisa.texto.toString().includes("-")) {
              let pesquisa = val[key].pesquisa.texto;
              pesquisa = pesquisa.toString().replace(/ /g, "%20");
              if (pesquisa === null || pesquisa === "") {
                pesquisa = null;
              } else {
                if (pesquisa !== "" && pesquisa !== null) {
                  typingTimer = setTimeout(() => {
                    this.doneTyping(pesquisa);
                  }, doneTypingInterval);
                }
              }
            }
          }
        });
      }
    }
  }
};
</script>
<style>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

#container {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  background-color: lightyellow;
}
#container > div {
  width: 100px;
  height: 100px;
  border: 2px dashed red;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

.md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}
</style>
